<div class="modal">
  <div class="modal-overlay"></div>
  <div class="modal-dialog modal-fullscreen modal-dialog-scrollable">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header justify-content-between">
        <div class="col d-flex align-items-center">
          <div class="logo">
            <img src="assets/img/logo.png" alt="Logo" class="logo-img" />
          </div>
          <h4 class="modal-title">evaluation</h4>
        </div>
        <div
          class="col d-flex align-items-center justify-content-center text-center">
          <h3 class="project-title mb-0">
            {{ title }}
          </h3>
        </div>
        <div class="col d-flex align-items-end justify-content-end text-end">
          <button
            type="button"
            class="btn btn-primary btn-sm leave-button pb-3"
            (click)="cancelModal()"
            data-bs-dismiss="modal">
            <app-icon
              class="mt-2"
              [type]="'x'"
              [width]="20"
              [height]="20"
              [strokeWidth]="3"></app-icon
            >Leave
          </button>
        </div>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
      <!-- Footer-->
    </div>
  </div>
</div>
