<header class="app-header">
  <div class="logo">
    <img src="assets/img/logo.png" alt="Logo" class="logo-img" />
  </div>
  <div class="user-section">
    <div class="user-info" (click)="toggleDropdown()">
      <div class="user-initials">SS</div>
      <span class="user-name">{{ userName }}</span>
      <button class="arrow-button">
        <app-icon [type]="'chevron-down'" [width]="18" [height]="18">
        </app-icon>
      </button>
    </div>
    <div class="dropdown-menu" *ngIf="isDropdownOpen">
      <div class="my-account">
        <span class="user-icon">
          <button class="user-btn">
            <app-icon
              [type]="'user'"
              [width]="36"
              [height]="36"
              [color]="'#082b49'">
            </app-icon>
          </button>
        </span>
        <span class="user-details">
          <div class="name">Suman Shaw</div>
          <div class="mail">suman.shaw&#64;ghd.com</div>
        </span>
      </div>
      <ul class="dropdown-menu-list-items">
        <li class="Settings">Settings</li>
        <li (click)="logout()">Log out</li>
      </ul>
    </div>
  </div>
</header>
