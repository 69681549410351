<div class="modal fade show" tabindex="-1" role="dialog">
  <div
    class="modal-dialog modal-{{ modalSize }} modal-dialog-centered"
    role="document">
    <div class="modal-content">
      <!-- Modal Header -->
      <!--   <div class="modal-header border-0">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          (click)="closeModal()"
          aria-label="Close"></button>
      </div> -->
      <!-- Modal Body -->
      <div class="modal-body">
        <!-- Here the content of the modal will be dynamically loaded -->
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
