import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-global-modal',
  templateUrl: './global-modal.component.html',
  styleUrls: ['./global-modal.component.scss'],
})
export class globalModalComponent {
  @Input() title = '';
  @Output() closeModal = new EventEmitter<void>();

  cancelModal() {
    this.closeModal.emit();
    document.body.classList.remove('modal-open');
  }
}
