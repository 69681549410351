import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IconComponent } from './components/icon/icon.component';
import { HeaderComponent } from './components/header/header.component';
import { ModalComponent } from './components/modal/modal.component';
import { globalModalComponent } from './components/global-modal/global-modal.component';
import { LoaderComponent } from './components/loader/loader.component';
import { PageLoaderComponent } from './components/page-loader/page-loader.component';
import { FormsModule } from '@angular/forms';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

@NgModule({
  declarations: [
    IconComponent,
    HeaderComponent,
    ModalComponent,
    globalModalComponent,
    LoaderComponent,
    PageLoaderComponent,
    TextEditorComponent,
    ConfirmDialogComponent,
  ],
  exports: [
    IconComponent,
    RouterModule,
    HeaderComponent,
    ModalComponent,
    globalModalComponent,
    LoaderComponent,
    PageLoaderComponent,
    ConfirmDialogComponent,
    TextEditorComponent,
  ],
  imports: [CommonModule, RouterModule, FormsModule, CKEditorModule],
})
export class CoreModule {}
