import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthStoreService {
  private teamMemberId$: BehaviorSubject<string> = new BehaviorSubject('');
  private nodeId$: BehaviorSubject<string> = new BehaviorSubject('');
  private customerId$: BehaviorSubject<string> = new BehaviorSubject('');

  getTeamMemberId() {
    return this.teamMemberId$?.getValue();
  }

  setTeamMemberId(value: string) {
    return this.teamMemberId$.next(value);
  }
  getNodeId() {
    return this.nodeId$?.getValue();
  }

  setNodeId(value: string) {
    return this.nodeId$.next(value);
  }
  getCustomerId() {
    return this.customerId$?.getValue();
  }

  setCustomerId(value: string) {
    return this.customerId$.next(value);
  }
}
